<template>
  <div class="content">
    <h1>Terms of Service</h1>

    <p>Last updated: July 30, 2021</p>

    <h3>Definitions</h3>

    <p>For the purposes of these Terms of Service:</p>
    <ul>
      <li>
        <b>Customer ("you")</b> means the person, firm or company who purchases Services
        from the Company.
      </li>
      <li>
        <b>Contract</b> refers to the contract between the Customer and Tax ID Pro for the
        Subscription Plan in accordance with these Terms.
      </li>
      <li>
        <b>Intellectual Property</b> means all patents, rights to inventions, utility
        models, copyright and related rights, trademarks, service marks, trade, business
        and domain names, rights in trade dress or get-up, rights in goodwill or to sue
        for passing off, unfair competition rights, rights in designs, rights in computer
        software, database right, rights in confidential information (including know-how
        and trade secrets) and any other intellectual property rights, in each case
        whether registered or unregistered and including all applications for and renewals
        or extensions of such rights, and all similar or equivalent rights or forms of
        protection in any part of the world.
      </li>
      <li>
        <b>Subscription Plan</b> is the periodically renewing plan selected by the
        Customer, setting out the Services to be provided by the Company (as well as the
        price and periodic basis for payment) in accordance with the Contract.
      </li>
      <li>
        <b>Free Plan</b> means a free version of subscription with a number of limitations
        of use.
      </li>
      <li>
        <b>Upgrade/Downgrade</b> refers to the upgrading or downgrading of the Customer's
        Subscription Plan to a lower or higher level, resulting in the availability of
        different features, the arising of certain limitations, lower or higher amounts to
        be paid periodically by the Customer, etc.
      </li>
      <li>
        <b>Services</b> are the services to be provided by the Company under the Contract,
        as described in further detail throughout this website.
      </li>
      <li><b>Company ("we", "our", "us")</b> refers to Tax ID Pro.</li>
    </ul>
    <h3>Basis Of Contract</h3>

    <p>
      A Customer's request through this Website for the provision of the Subscription Plan
      constitutes an offer by the Customer to purchase the Services in accordance with
      these Terms &amp; Conditions and any other applicable legal documents referred to
      herein.
    </p>
    <p>
      The Customer's request shall only be deemed to be accepted if Tax ID Pro has
      received cleared payment in full of the relevant Subscription Fees at which point
      and on which date the Contract between us shall come into existence ("Commencement
      Date").
    </p>
    <p>
      These Terms apply to the Contract to the exclusion of any other terms that the
      Customer seeks to impose or incorporate, or which are implied by trade, custom,
      practice or course of dealing.
    </p>

    <h3>Subscriptions &amp; Services</h3>

    <p>
      Tax ID Pro shall, during the Subscription Term, use reasonable endeavours to provide
      the Services in accordance with the Subscription Plan in all material respects and
      subject to these Terms.
    </p>

    <h3>Customer's Obligations</h3>

    <p>
      The Customer shall ensure to make use of the Subscription Plan, the Website and the
      Content in accordance with these Terms &amp; Conditions and shall be responsible for
      any breach of these Terms &amp; Conditions.
    </p>
    <p>
      The Customer shall further co-operate with the Company in all matters relating to
      the Services; and provide in a timely manner such information as the Company may
      request, and ensure that such information is accurate in all material respects.
    </p>

    <h3>Charges &amp; Payment</h3>

    <p>
      The Customer shall carry out payment of the total amount set out in the Subscription
      Plan using one of the following payment methods: Credit Card or Debit Card.
    </p>
    <p>
      The Customer shall make the first payment immediately pursuant to the purchase order
      and thereafter on the first day of each new period the Customer's Subscription Plan
      is renewed. The Customer agrees that, for each period, the amount set out in the
      Subscription plan may be withdrawn automatically from the Customer's account.
    </p>
    <p>
      Upgrade of Subscription: Should the Customer decide to upgrade to a higher
      Subscription Plan, he shall be advised that any remaining days or amounts of his
      previous Subscription Plan will be deducted from the new Upgrade Invoice amount.
    </p>
    <p>
      Downgrade of Subscription: Should the Customer decide to downgrade to a lower or
      free Subscription Plan, he shall be advised that his current Subscription Plan will
      remain active until the end of the current billing period.
    </p>
    <p>
      Without prejudice to any other right or remedy that the Company may have, if the
      Customer fails to pay a Recurring Invoice within 30 days, or pay an Initial Invoice
      or Upgrade Invoice within a maximum of 2 hours, the Company may:
    </p>
    <ul>
      <li>cancel the Upgrade process; or</li>
      <li>downgrade the Customer's Subscription to the Free Plan; or</li>
      <li>terminate the Contract with immediate effect; or</li>
      <li>suspend all or some Services until payment has been made in full</li>
    </ul>

    <p>
      Refunds for Unassigned Payments: Any payments made by the Customer that cannot be
      assigned to pay any of the Customer's open invoices will be automatically refunded
      in full after a maximum of 32 days.
    </p>
    <p>
      Other Refunds: The Customer shall be aware that, if not specified or declared
      otherwise, due amounts paid by the Customer which are used as payments for open
      invoices (as set out in the Subscription Plan) are not refunded. However, under
      distinct circumstances and in case of appropriate reasons for the Customer's
      dissatisfaction, applying only for Customers on a yearly subscription, partial or
      complete refunds of paid amounts for the individual billing period may be offered.
    </p>

    <p>The Customer shall be given the possibility to change his Payment Frequency by:</p>
    <ul>
      <li>
        downgrading to the Free Plan and upgrading once the Free Plan becomes effective;
        or
      </li>
      <li>contacting the Tax ID Pro support team and requesting a manual change.</li>
    </ul>

    <h3>Term &amp; Termination</h3>

    <p>
      The Contract shall, unless otherwise terminated as provided in this clause, commence
      on the Commencement Date and shall be automatically renewed for each following
      billing period (as set out in the Customer's initial purchase order), unless either
      party notifies the other party of termination, in writing, at least 30 days before
      the end of the Initial Subscription Term or any Renewal Period, in which case this
      Contract shall terminate upon the expiry of the applicable Initial Subscription Term
      or Renewal Period, otherwise terminated in accordance with the provisions of these
      Terms.
    </p>

    <p>
      Without prejudice to any other rights or remedies to which the parties may be
      entitled, either party may terminate the Contract without liability to the other if:
    </p>
    <ul>
      <li>
        the other party commits a material breach of any of these Terms and (if such a
        breach is remediable) fails to remedy that breach within 30 days of that party
        being notified in writing of the breach; or
      </li>
      <li>
        an order is made or a resolution is passed for the winding up of the other party,
        or circumstances arise which entitle a court of competent jurisdiction to make a
        winding-up order in relation to the other party; or
      </li>
      <li>
        an order is made for the appointment of an administrator to manage the affairs,
        business and property of the other party, or documents are filed with a court of
        competent jurisdiction for the appointment of an administrator of the other party,
        or notice of intention to appoint an administrator is given by the other party or
        its directors or by a qualifying floating charge holder (as defined in paragraph
        14 of Schedule B1 to the Insolvency Act 1986); or
      </li>
      <li>
        a receiver is appointed of any of the other party's assets or undertaking, or if
        circumstances arise which entitle a court of competent jurisdiction or a creditor
        to appoint a receiver or manager of the other party, or if any other person takes
        possession of or sells the other party's assets; or
      </li>
      <li>
        the other party makes any arrangement or composition with its creditors, or makes
        an application to a court of competent jurisdiction for the protection of its
        creditors in any way; or
      </li>
      <li>the other party ceases, or threatens to cease, to trade; or</li>
      <li>
        the other party takes or suffers any similar or analogous action in any
        jurisdiction in consequence of debt.
      </li>
    </ul>

    <p>On termination of this agreement for any reason:</p>
    <ul>
      <li>all licenses granted under the Contract shall immediately terminate;</li>
      <li>
        the Customer shall make no further use of any Content or the Subscription Plan;
      </li>
      <li>
        Tax ID Pro shall cancel all user name, email addresses and passwords used in
        connection with the Customer's subscription so that the Customer is no longer able
        to access the Subscription Plan;
      </li>
      <li>
        the accrued rights of the parties as at termination, or the continuation after
        termination of any provision expressly stated to survive or implicitly surviving
        termination, shall not be affected or prejudiced.
      </li>
      <li>
        If the Customer terminates the Contract, Tax ID Pro shall not be obliged to refund
        any part of the Subscription Fees already paid by the Customer.
      </li>
    </ul>

    <p>
      The Customer may terminate the validity of the chosen Subscription Plan at any time,
      being aware that, in case of a Downgrade, the current Subscription Plan remains
      active and available for use until the end of the current period, and that, in case
      of an Account Deletion, any use of this service including any paid features set out
      on the Subscription Plan will become unavailable immediately.
    </p>
    <p>
      If not terminated by the Customer or Company, the Customer agrees that the
      Customer's contract will remain valid for each period and periodically renew as set
      out in the Subscription Plan.
    </p>
    <p>
      The Customer may terminate the contract and the validity of the Customer's
      Subscription Plan by:
    </p>
    <ul>
      <li>downgrading his or her Subscription Plan; or</li>
      <li>
        giving notice by visiting this page on our website:
        <router-link :to="{ name: 'Contact' }">https://taxid.pro/contact</router-link>.
      </li>
    </ul>

    <h3>Notices</h3>

    <p>
      Any notice or other communication required to be given to a party under or in
      connection with this Contract shall be in writing and shall be delivered to the
      other party personally or sent by prepaid first-class post, recorded delivery or by
      commercial courier, at its registered office (if a company) or (in any other case)
      its principal place of business, or sent by fax to the other party's main fax
      number.
    </p>
    <p>
      Any notice or other communication shall be deemed to have been duly received if
      delivered personally, when left at the address referred to above or, if sent by
      pre-paid first-class post or recorded delivery, at 9.00 am on the second Business
      Day after posting, or if delivered by commercial courier, on the date and at the
      time that the courier's delivery receipt is signed, or if sent by fax, on the next
      Business Day after transmission.
    </p>
    <p>
      This condition shall not apply to the service of any proceedings or other documents
      in any legal action. For the purposes of this clause, "writing" shall not include
      e-mails and for the avoidance of doubt notice given under this Contract shall not be
      validly served if sent by e-mail.
    </p>

    <h3>Force Majeure</h3>

    <p>
      Tax ID Pro shall have no liability to the Customer under the Contract if it is
      prevented from or delayed in performing its obligations under the Contract, or from
      carrying on its business, by acts, events, omissions or accidents beyond its
      reasonable control, including, without limitation, strikes, lock-outs or other
      industrial disputes (whether involving the workforce of Tax ID Pro or any other
      party), failure of a utility service or transport or telecommunications network, act
      of God, war, riot, civil commotion, malicious damage, compliance with any law or
      governmental order, rule, regulation or direction, accident, breakdown of plant or
      machinery, fire, flood, storm or default of Tax ID Pro or sub-contractors, provided
      that the Customer is notified of such an event and its expected duration.
    </p>

    <h3>Waiver</h3>

    <p>
      A waiver of any right under the Contract is only effective if it is in writing and
      shall not be deemed to be a waiver of any subsequent breach or default. No failure
      or delay by a party in exercising any right or remedy under the Contract or by law
      shall constitute a waiver of that or any other right or remedy, nor preclude or
      restrict its further exercise. No single or partial exercise of such right or remedy
      shall preclude or restrict the further exercise of that or any other right or
      remedy.
    </p>
    <p>
      Unless specifically provided otherwise, rights arising under the Contract are
      cumulative and do not exclude rights provided by law.
    </p>

    <h3>Severance</h3>

    <p>
      If a court or any other competent authority finds that any provision of the Contract
      (or part of any provision) is invalid, illegal or unenforceable, that provision or
      part-provision shall, to the extent required, be deemed deleted, and the validity
      and enforceability of the other provisions of the Contract shall not be affected.
    </p>
    <p>
      If any invalid, unenforceable or illegal provision of the Contract would be valid,
      enforceable and legal if some part of it were deleted, the provision shall apply
      with the minimum modification necessary to make it legal, valid and enforceable.
    </p>

    <h3>No Partnership</h3>

    <p>
      Nothing in the Contract is intended to, or shall be deemed to, constitute a
      partnership or joint venture of any kind between any of the parties, nor constitute
      any party the agent of another party for any purpose. No party shall have authority
      to act as agent for, or to bind, the other party in any way.
    </p>

    <h3>Third Parties</h3>

    <p>
      A person who is not a party to the Contract shall not have any rights under or in
      connection with it.
    </p>

    <h3>Contacting Us</h3>

    <p>
      Should you have any questions, complaints, or comments about this Service Agreement,
      this service, or website, or require further clarification of any kind, please do
      not hesitate to contact us by visiting this page on our website:
      <router-link :to="{ name: 'Contact' }">https://taxid.pro/contact</router-link>.
    </p>
  </div>
</template>

<script>
export default {
  name: 'TermsOfService',
  mounted() {
    window.scrollTo(0, 0)
  },
}
</script>